import React from 'react';
import { isAuthenticated, login, getIdToken } from "../utils/auth"
import Layout from '../components/Layout';
import uuid from 'react-uuid'
import { format } from 'date-fns'

const SMSPage = () => {

    const [sendSingleNumber, setSendSingleNumber] = React.useState("");
    const [sendSingleMessage, setSendSingleMessage] = React.useState("");

    const [sendBulkNumber, setSendBulkNumber] = React.useState("");
    const [sendBulkMessage, setSendBulkMessage] = React.useState("");

    const [tableData, setTableData] = React.useState("");
    let tableDataTemp = [];

    const handleSingleNumberChange = (event) => {
        const value = event.target.value

        setSendSingleNumber(value)
    }

    const handleSingleMessageChange = (event) => {
        const value = event.target.value

        setSendSingleMessage(value)
    }

    const handleSendMessage = async (number, message) => {

        const token = getIdToken();

        const requestHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        });

        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({ number, message }),
        };

        try {
            await fetch(process.env.GATSBY_SMS_SEND_URL, requestOptions)
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleSingleSendMessage = async () => {
        try {
            await handleSendMessage(sendSingleNumber, sendSingleMessage)
            setSendSingleNumber('');
            setSendSingleMessage('');
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleBulkNumberChange = (event) => {
        const value = event.target.value

        setSendBulkNumber(value)
    }

    const handleBulkMessageChange = (event) => {
        const value = event.target.value

        setSendBulkMessage(value)
    }

    const handleBulkSendMessage = async () => {
        let numbers = sendBulkNumber.split('\n');

        for (let i = 0; i < numbers.length; i++) {
            try {
                await handleSendMessage(numbers[i], sendBulkMessage)
            }
            catch (err) {
                console.log(err)
            }
        }

        setSendBulkNumber('');
        setSendBulkMessage('');
    }

    const addDataToTableSource = (data) => {
        let date = format(new Date(data.date_created), 'yyyy-MM-dd HH:mm:ss')
        let direction = data.direction.replace("-api", "").replace("-reply", "")
        let number;

        if (direction === "inbound") {
            number = data.from
        }
        else {
            number = data.to
        }

        let tableRow = {
            direction: direction,
            number,
            message: data.body,
            date
        }

        tableDataTemp.push(tableRow);
    }

    const fetchMessages = async () => {

        const token = getIdToken();

        const requestHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        });

        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({ number: '' }),
        };

        const responseInbound = await fetch(process.env.GATSBY_SMS_INBOUND_URL, requestOptions);
        const responseInboundJson = await responseInbound.json();

        responseInboundJson.forEach(data => {
            addDataToTableSource(data);
        })

        let responseOutbound = await fetch(process.env.GATSBY_SMS_OUTBOUND_URL, requestOptions);
        let responseOutboundJson = await responseOutbound.json();

        responseOutboundJson.forEach(data => {
            addDataToTableSource(data);
        })

        setTableData(tableDataTemp);
    }

    const MessagesTable = () => {
        return ((tableData !== undefined) && (tableData.length !== undefined) && tableData.length > 0 && (
            <tbody>
                {tableData.map((row) => (
                    <tr key={uuid()}>
                        <td>{row.direction}</td>
                        <td>{row.number}</td>
                        <td>{row.message}</td>
                        <td>{row.date}</td>
                    </tr>
                ))}
            </tbody>
        ))
    }

    if (!isAuthenticated()) {
        login()
        return <p>Redirecting to login...</p>
    }

    return (
        <Layout fullMenu>
            <section id="banner">
                <header>
                    <div className="inner">
                        <h2>SMS Functions</h2>
                    </div>
                </header>
                <div className="wrapper" id="single">
                    <div className="inner">
                        <h3>Send Single SMS</h3><br /><br />
                        <form id="form_single" method="post" action="/api/v1/send">
                            <div className="row gtr-uniform">
                                <div className="col-6 col-12-xsmall">
                                    <label htmlFor="number-single">Phone Number</label>
                                    <input type="number" name="number-single" id="number-single" value={sendSingleNumber} onChange={(event) => handleSingleNumberChange(event)} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="message-single">Message</label>
                                    <textarea name="message-single" id="message-single" rows="6" value={sendSingleMessage} onChange={(event) => handleSingleMessageChange(event)}></textarea>
                                </div>
                                <div className="col-12">
                                    <ul className="actions">
                                        <li><a href="#single" className="button primary" onClick={() => handleSingleSendMessage()}>Send Single Message</a></li>
                                    </ul>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="wrapper" id="bulk">
                    <div className="inner">
                        <h3>Send Bulk SMS</h3><br /><br />
                        <form id="form_bulk" method="post" action="/api/v1/send">
                            <div className="row gtr-uniform">
                                <div className="col-6 col-12-xsmall">
                                    <label htmlFor="number-bulk">Phone Numbers</label>
                                    <textarea id="number-bulk" type="message" name="number-bulk" rows="3" value={sendBulkNumber} onChange={(event) => handleBulkNumberChange(event)}></textarea>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="message-bulk">Message</label>
                                    <textarea name="message-bulk" id="message-bulk" rows="6" value={sendBulkMessage} onChange={(event) => handleBulkMessageChange(event)}></textarea>
                                </div>
                                <div className="col-12">
                                    <ul className="actions">
                                        <li><a href="#bulk" className="button primary" onClick={() => handleBulkSendMessage()}>Send Bulk Message</a></li>
                                    </ul>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="wrapper" id="read">
                    <div className="inner">
                        <h3>Read SMS Messages</h3><br /><br />
                        <div className="col-12">
                            <ul className="actions">
                                <li><a href="#read" className="button primary" onClick={() => fetchMessages()}>Get Messages</a></li>
                            </ul>
                        </div>
                        <div className="table-wrapper">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Direction</th>
                                        <th>Number</th>
                                        <th>Message</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <MessagesTable />
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
};

export default SMSPage;